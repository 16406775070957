import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

//components
import Layout from '../components/layout';
import Title from '../components/title';
import SEO from '../components/seo';

// Styles
import * as styles from '../styles/pages/hotels.module.scss';

const Hotels = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulHotels {
          edges {
            node {
              description {
                raw
              }
              hotelName
              images {
                gatsbyImageData(
                  quality: 100
                  width: 600
                  height: 450
                  layout: FULL_WIDTH
                )
              }
              shortDescription {
                shortDescription
              }
              slug
              tags
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Title picture title='Hotels' />
      <SEO title='Hotels' />
      <Container className='mt-5 pt-5'>
        <Row className='g-5'>
          {data.allContentfulHotels.edges.map(({ node }, idx) => {
            let image = null;
            if (node.images) {
              image = getImage(node.images[0]);
            }

            return (
              <Col sm={12} md={6} lg={4}>
                <Card
                  className={`w-100 ${styles.card}`}
                  onClick={() => navigate(`/hotels/${node.slug}`)}
                >
                  {/* <img
                    class={`card-img-top rounded ${styles.image}`}
                    src={node.images[0]}
                    alt=""
                  ></img> */}
                  <GatsbyImage
                    image={image}
                    alt={node.name}
                    className={styles.image}
                  />
                  <Card.Body>
                    <Card.Title>{node.hotelName}</Card.Title>
                    <hr />
                    <Card.Text>
                      {node.shortDescription.shortDescription}
                    </Card.Text>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Layout>
  );
};

export default Hotels;
